const base = 5;

export default {
  base: base,
  xs: (base * 1.5),   // 7.5
  sm: (base * 2),     // 10
  md: (base * 3),     // 15
  lg: (base * 4.5),   // 22.5
  xl: (base * 6),     // 30
  xxl: (base * 9),    // 45
};
